/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
;var SearchKeyWords = function (options) {


    var keyWords = options.keyWords;
    var input = $(options.selector);
    var keyWord = keyWords[0];
    var j = 0;
    var k = 0;
    var direction = 'asc';
    var speed = options.speed;
    var myVar = setTimeout(type, speed);

    function type() {
        var _speed = speed;
        clearTimeout(myVar);
        input.attr("placeholder", keyWord.substring(0, j));

        if (j - 3 > keyWord.length && direction == 'asc') {
            direction = 'desc';
        } else if (j == -4) {
            direction = 'asc';
        }
        if (direction == 'asc') {
            j++;
        } else {
            _speed = _speed / 2;
            j--;
        }

        if (direction == 'desc' && j == 0) {
            if (k == keyWords.length - 1) {
                k = 0;
            } else {
                k++;
            }
            keyWord = keyWords[k];
        }
        myVar = setTimeout(type, _speed);
    }

    input.focusin(function () {
        clearTimeout(myVar);
        input.attr("placeholder", '');
    });

    input.focusout(function () {
        myVar = setTimeout(type, speed);
        j = 0;
        direction = 'asc';
    });
};